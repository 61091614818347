import { createStore } from 'vuex';
import config from '../config.js'

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                      clientId: config.clientId,
                      authority: config.authority,                      
                      redirectUri: config.redirectUri,
                      postLogoutRedirectUri: config.redirectUri + 'notloggedin.html'
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            },
            accessToken: '',
            idToken: ''
        };
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setIdToken(state, token) {
            state.idToken = token;
        }
    }
});

export default store;