<template>
        <td>{{SingleOnttrokkenStam.serialnum}}</td>
        <td>{{SingleOnttrokkenStam.ntrsnmaint}}</td>
        <td>{{SingleOnttrokkenStam.plusanplocationDisplay}}</td>
        <td><TimeDateFormatter :timestamp="SingleOnttrokkenStam.prognose" /></td>
</template>

<script>
import TimeDateFormatter from "@/components/Formatters/TimeDateFormatter";
export default { 
  components: { TimeDateFormatter },
 props: ["SingleOnttrokkenStam"] };
</script>

