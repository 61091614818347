<template>
  <div class="button-group">
    <button
      type="button"
      class="btn btn-primary dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        class="filter-icon mr-2"
        alt="filter"
        src="@/assets/icons/filter--small.svg"
      />
      Filter op Reden Binnenkomst
    </button>
    <ul class="dropdown-menu">
      <button
        class="dropdown-item"
        @click="updateFilter('all'), emptySelectedRedenBinnenkomsten()"
      >
        Alle Reden Binnenkomsten
      </button>
      <div class="dropdown-divider"></div>
      <li
        v-for="uniqueRedenBinnenkomst in uniqueRedenBinnenkomsten"
        :key="uniqueRedenBinnenkomst"
      >
        <input
          class="mr-2 ml-2"
          type="checkbox"
          :value="uniqueRedenBinnenkomst"
          v-model="selectedRedenBinnenkomsten"
          @click="updateFilter(uniqueRedenBinnenkomst)"
        />
        <label>{{ uniqueRedenBinnenkomst }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["uniqueRedenBinnenkomsten"],
  emits: ["filterChange", "selectedRedenBinnenkomsten"],
  data() {
    return {
      selectedRedenBinnenkomsten: [],
    };
  },
  methods: {
    updateFilter(by) {
      this.$emit("filterChange", by);
    },
    emptySelectedRedenBinnenkomsten() {
      this.selectedRedenBinnenkomsten = [];
    },
  },
  watch: {
    selectedRedenBinnenkomsten() {
      this.$emit("selectedRedenBinnenkomsten", this.selectedRedenBinnenkomsten);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  ul {
    color: $ns-blue;
    font-size: 16px;
  }
  .btn-primary {
    background-color: $ns-blue !important;
    border-color: $ns-blue !important;
  }
}
</style>


