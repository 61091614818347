<template>
  <LoadingIndicator :loading="loading" />
  <div class="container">
    <div class="row mt-4 pr-3">
      <div class="col-12">
        <div class="button-group float-right">
          <VariantFilter
            @filterChange="currentVariant = $event"
            @selectedVariants="variantSelection = $event"
            :uniqueVariants="uniqueVariants"
          />
        </div>
        <div class="button-group float-right mr-2">
          <LocationFilter
            @filterChange="currentLocation = $event"
            @selectedLocations="locationSelection = $event"
            :uniqueLocations="uniqueLocations"
          />
        </div>
        <div class="button-group float-right mr-2">
          <RedenBinnenkomstFilter
            @filterChange="currentRedenBinnenkomst = $event"
            @selectedRedenBinnenkomsten="redenBinnenkomstSelection = $event"
            :uniqueRedenBinnenkomsten="uniqueRedenBinnenkomsten"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="col-12">
          <table class="table mt-4" aria-label="Treinen NZ Corridor">
            <thead>
              <tr>
                <th scope="col" colspan="6">Alle stammen</th>
                <th scope="col" class="text-right">
                  <a
                    class="foldTable"
                    data-toggle="collapse"
                    nohref
                    data-target="#alleStammen"
                    role="button"
                    aria-expanded="false"
                    ><span class="fas fa-sort"></span
                  ></a>
                </th>
              </tr>
              <tr>
                <td @click="sortAS('serialnum')">
                  Stam
                  <span
                    :class="[
                      currentSortAS === 'serialnum'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortAS('status')">
                  Status
                  <span
                    :class="[
                      currentSortAS === 'status'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortAS('vlootregiestatus')">
                  Vlootregie
                  <span
                    :class="[
                      currentSortAS === 'vlootregiestatus'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortAS('plusamodelDisplay')">
                  Variant
                  <span
                    :class="[
                      currentSortAS === 'plusamodelDisplay'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortAS('plusanplocationDisplay')">
                  Locatie
                  <span
                    :class="[
                      currentSortAS === 'plusanplocationDisplay'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortAS('ntrsnmaint')">
                  Reden
                  <span
                    :class="[
                      currentSortAS === 'ntrsnmaint'
                        ? currentSortDirAS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td>Samenstelling</td>
              </tr>
            </thead>
            <tbody class="collapse show" id="alleStammen">
              <tr
                v-for="SingleStam in filteredAlleStammen"
                :key="SingleStam.id"
              >
                <SingleStam :SingleStam="SingleStam" />
              </tr>
              <tr v-if="Object.keys(filteredAlleStammen).length === 0">
                <td class="no-data">Geen Data beschikbaar</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="col-12">
          <table class="table mt-4" aria-label="Treinen NZ Corridor">
            <thead>
              <tr>
                <th scope="col" colspan="4">Niet gebruikte locomotieven</th>
                <th scope="col" class="text-right">
                  <a
                    class="foldTable"
                    data-toggle="collapse"
                    nohref
                    data-target="#losse_loccen"
                    role="button"
                    aria-expanded="false"
                    aria-controls="losse_loccen"
                    ><span class="fas fa-sort"></span
                  ></a>
                </th>
              </tr>
              <tr>
                <td @click="sortNGL('serialnum')">
                  Loc
                  <span
                    :class="[
                      currentSortNGL === 'serialnum'
                        ? currentSortDirNGL === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortNGL('ntrsnmaint')">
                  Reden
                  <span
                    :class="[
                      currentSortNGL === 'ntrsnmaint'
                        ? currentSortDirNGL === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortNGL('plusanplocationDisplay')">
                  Locatie
                  <span
                    :class="[
                      currentSortNGL === 'plusanplocationDisplay'
                        ? currentSortDirNGL === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortNGL('p_left')">
                  Km tot onderhoud
                  <span
                    :class="[
                      currentSortNGL === 'p_left'
                        ? currentSortDirNGL === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortNGL('prognose')">
                  Prognose
                  <span
                    :class="[
                      currentSortNGL === 'prognose'
                        ? currentSortDirNGL === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
              </tr>
            </thead>
            <tbody class="collapse show" id="losse_loccen">
              <tr
                v-for="SingleNietGebruikteLocomotief in filteredNietGebruikteLocomotieven"
                :key="SingleNietGebruikteLocomotief.id"
              >
                <SingleNietGebruikteLocomotief
                  :locomotief="SingleNietGebruikteLocomotief"
                />
              </tr>
              <tr
                v-if="
                  Object.keys(filteredNietGebruikteLocomotieven).length === 0
                "
              >
                <td class="no-data">Geen Data beschikbaar</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <table class="table mt-4" aria-label="Treinen NZ Corridor">
            <thead>
              <tr>
                <th scope="col" colspan="3">Onttrokken stammen</th>
                <th scope="col" class="text-right">
                  <a
                    class="foldTable"
                    data-toggle="collapse"
                    nohref
                    data-target="#overige_stammen"
                    role="button"
                    aria-expanded="false"
                    aria-controls="overige_stammen"
                    ><span class="fas fa-sort"></span
                  ></a>
                </th>
              </tr>
              <tr>
                <td @click="sortOS('serialnum')" scope="col">
                  Stam
                  <span
                    :class="[
                      currentSortOS === 'serialnum'
                        ? currentSortDirOS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortOS('ntrsnmaint')" scope="col">
                  Reden
                  <span
                    :class="[
                      currentSortOS === 'ntrsnmaint'
                        ? currentSortDirOS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortOS('plusanplocationDisplay')" scope="col">
                  Locatie
                  <span
                    :class="[
                      currentSortOS === 'plusanplocationDisplay'
                        ? currentSortDirOS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
                <td @click="sortOS('prognose')" scope="col">
                  Prognose
                  <span
                    :class="[
                      currentSortOS === 'prognose'
                        ? currentSortDirOS === 'asc'
                          ? 'fas fa-sort-up'
                          : 'fas fa-sort-down'
                        : 'fas fa-sort',
                    ]"
                  >
                  </span>
                </td>
              </tr>
            </thead>
            <tbody class="collapse show" id="overige_stammen">
              <tr
                v-for="SingleOnttrokkenStam in filteredOnttrokkenStammen"
                :key="SingleOnttrokkenStam.id"
              >
                <SingleOnttrokkenStam
                  :SingleOnttrokkenStam="SingleOnttrokkenStam"
                />
              </tr>
              <tr v-if="Object.keys(filteredOnttrokkenStammen).length === 0">
                <td class="no-data">Geen Data beschikbaar</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../config.js";
import { getSortedArray } from '../utils.js';
import SingleStam from "../components/TreinenNZCorridor/SingleStam.vue";
import SingleOnttrokkenStam from "../components/TreinenNZCorridor/SingleOnttrokkenStam.vue";
import SingleNietGebruikteLocomotief from "../components/TreinenNZCorridor/SingleNietGebruikteLocomotief.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import VariantFilter from "../components/Filters/VariantFilter.vue";
import LocationFilter from "../components/Filters/LocationFilter.vue";
import RedenBinnenkomstFilter from "../components/Filters/RedenBinnenkomstFilter.vue";


export default {
  name: "Treinen NZ Corridor",
  components: {
    SingleStam,
    SingleOnttrokkenStam,
    SingleNietGebruikteLocomotief,
    LoadingIndicator,
    VariantFilter,
    LocationFilter,
    RedenBinnenkomstFilter,
  },
  data() {
    return {
      title: "Treinen Noord-Zuid Corridor",
      assets: [],
      alleStammen: [],
      OnttrokkenStammen: [],
      NietGebruikteLocomotieven: [],
      locationSelection: [],
      variantSelection: [],
      redenBinnenkomstSelection: [],
      loading: false,
      currentSortDirAS: "asc",
      currentSortDirOS: "asc",
      currentSortDirNGL: "asc",
      currentSortAS: "serialnum",
      currentSortOS: "serialnum",
      currentSortNGL: "serialnum",
      uniqueVariants: null,
      uniqueLocations: null,
      uniqueRedenBinnenkomsten: null,
      config: config ?? { backendUri: "" },
    };
  },
  // on create set page title and get assets from backend
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
    this.getAssets();
  },
  methods: {
    // Change sorting for Alle Stammen from asc to desc or reverse
    sortAS(s) {
      if (s === this.currentSortAS) {
        this.currentSortDirAS =
          this.currentSortDirAS === "asc" ? "desc" : "asc";
      }
      this.currentSortAS = s;
    },
    // Change sorting for ontrokken stammen from asc to desc or reverse
    sortOS(s) {
      if (s === this.currentSortOS) {
        this.currentSortDirOS =
          this.currentSortDirOS === "asc" ? "desc" : "asc";
      }
      this.currentSortOS = s;
    },
    // Change sorting for niet gebruikte locomotieven from asc to desc or reverse
    sortNGL(s) {
      if (s === this.currentSortNGL) {
        this.currentSortDirNGL =
          this.currentSortDirNGL === "asc" ? "desc" : "asc";
      }
      this.currentSortNGL = s;
    },
    // Make request to backend to get Assets
    getAssets() {
      this.loading = true;
      axios
        .get(this.config.backendUri + "/getAssetsNzCorridor" + this.locationSelection, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userIdToken")}`,
          },
        })
        .then((result) => {
          this.alleStammen = result.data.alleStammenData;
          this.OnttrokkenStammen = result.data.onttrokkenStammenData;
          this.NietGebruikteLocomotieven =
            result.data.NietGebruikteLocomotievenData;
          // Get unique values from data to fill dropdown
          if (result.data.alleStammenData.length !== 0) {
            this.getUniqueVariants();
            this.getUniqueLocations();
            this.getUniqueRedenBinnenkomsten();
          }
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.logIn();
          }
          this.errors.push(error);
        });
    },
    // get unique Variants values from all data
    getUniqueVariants() {
      const variants = {
        variant: this.alleStammen.map((stam) => stam.plusamodelDisplay),
      };
      const uniqueVariants = variants.variant
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();

      return (this.uniqueVariants = uniqueVariants);
    },
    // get unique location values from all data
    getUniqueLocations() {
      const locations = {
        location: this.alleStammen.map((stam) => stam.plusanplocationDisplay),
      };
      const uniquelocations = locations.location
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();

      return (this.uniqueLocations = uniquelocations);
    },
    // get unique reden binnenkomst values from all data
    getUniqueRedenBinnenkomsten() {
      var redenBinnenkomsten = {
        redenBinnenkomst: this.alleStammen.map((stam) => stam.ntrsnmaint),
      };
      var uniqueRedenBinnenkomsten = redenBinnenkomsten.redenBinnenkomst
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();
      //remove empty reden binnenkomst
      var filteredUniqueRedenBinnenkomsten = uniqueRedenBinnenkomsten.filter(
        (uniqueRedenBinnenkomst) => {
          return uniqueRedenBinnenkomst !== "";
        }
      );
      return (this.uniqueRedenBinnenkomsten = filteredUniqueRedenBinnenkomsten);
    },
  },

  computed: {
    sortedAlleStammen() {
      return getSortedArray(Object.values(this.alleStammen), this.currentSortAS, this.currentSortDirAS);
    },
    sortedOnttrokkenStammen() {
      return getSortedArray(Object.values(this.OnttrokkenStammen), this.currentSortOS, this.currentSortDirOS);
    },
    sortedNietGebruikteLocomotieven() {
      return getSortedArray(Object.values(this.NietGebruikteLocomotieven), this.currentSortNGL, this.currentSortDirNGL);
    },
    // Filter alle stammen on Variant, Reden binnenkomst and location
    filteredAlleStammen() {
      return this.sortedAlleStammen.filter((stam) => {
        // filter on location
        if (this.locationSelection.length !== 0) {
          if (!this.locationSelection.includes(stam.plusanplocationDisplay))
            return false;
        }
        // filter on reden binnenkomst
        if (this.redenBinnenkomstSelection.length !== 0) {
          if (!this.redenBinnenkomstSelection.includes(stam.ntrsnmaint)) {
            return false;
          }
        }
        // filter on variant
        if (this.variantSelection.length !== 0) {
          if (!this.variantSelection.includes(stam.plusamodelDisplay))
            return false;
        }
        // passed all checks or no filters applied
        return true;
      });
    },
    // Filter ontrokken stammen on Reden binnenkomst and location
    filteredOnttrokkenStammen() {
      return this.sortedOnttrokkenStammen.filter((stam) => {
        // filter on location
        if (this.locationSelection.length !== 0) {
          if (!this.locationSelection.includes(stam.plusanplocationDisplay))
            return false;
        }
        // filter on reden binnenkomst
        if (this.redenBinnenkomstSelection.length !== 0) {
          if (!this.redenBinnenkomstSelection.includes(stam.ntrsnmaint)) {
            return false;
          }
        }
        // passed all checks or no filters applied
        return true;
      });
    },
    // Filter niet gebruikte locomotieven on Reden binnenkomst and location
    filteredNietGebruikteLocomotieven() {
      return this.sortedNietGebruikteLocomotieven.filter((stam) => {
        // filter on location
        if (this.locationSelection.length !== 0) {
          if (!this.locationSelection.includes(stam.plusanplocationDisplay))
            return false;
        }
        // filter on reden binnenkomst
        if (this.redenBinnenkomstSelection.length !== 0) {
          if (!this.redenBinnenkomstSelection.includes(stam.ntrsnmaint)) {
            return false;
          }
        }
        // passed all checks or no filters applied
        return true;
      });
    },
  },
};
</script>
<style lang="scss">
a.foldTable,
a.foldTable:visited,
a.foldTable:active,
a.foldTable:hover {
  color: white;
}
a.foldCarriages,
a.foldCarriages:visited,
a.foldCarriages:active,
a.foldCarriages:hover {
  color: $ns-blue;
}
td {
  white-space: nowrap;
}
td.wrap {
  white-space: normal;
}
</style>