<template>
  <div class="container">
    <div class="button-group float-left mt-4 mb-4 w-25">
      <LocationSelect @locationSelection="handleLocationChange" />
    </div>
  </div>
  
  <ButtonSelect @buttonSelection="handleLocationChange($event.split(','))" />
</template>

<script>
import LocationSelect from "@/components/LocationSelect.vue";
import ButtonSelect from "@/components/Buttons.vue";

export default {
  name: "HerstellingenLocations",
  
  components: {
    LocationSelect,
    ButtonSelect,
  },

  data() {
    return {
      title: "Herstellingen locaties",
    };
  },
  
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
  },
  
  methods: {
    handleLocationChange(locationIds) {
      this.$router.push({ 
        name: "HerstellingenWorkOrders", 
        params: { locationIds: locationIds.join(',') },
      });
    },
  },
};
</script>
