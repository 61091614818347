<template>
  {{ formatedEstimatedDuration }}
</template>

<script>
export default {
  props: ["estdur"],
  computed: {
    formatedEstimatedDuration () {
          var factor = this.estdur;
      if (factor === 0 || factor === '') {
        factor ="n.b."; 
      } else {
        const hours = Math.floor(factor);
        const minutes = Math.round ((factor - hours) * 60 )
        return hours + ':' + minutes 

      }
      return factor;
    },
  },
};
</script>
