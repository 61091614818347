<template>
  <nav class="navbar navbar-light bg-white" id="nav">
    <a class="navbar-brand" href="#">
      <img
        class="logo"
        alt="NS Logo"
        src="@/assets/Nederlandse_Spoorwegen_logo.svg"
        id="logo"
      />
      <span id="pageTitle">OSDirecten Dashboard</span>
    </a>
    <button
      v-show="$route.name !== 'Presentatie'"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item text-center">
          <router-link
            :to="{ name: 'SB Productiedashboard' }"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarNav"
            >SB Productiedashboard</router-link
          >
        </li>
        <li class="nav-item text-center">
          <router-link
            :to="{ name: 'HerstellingenLocations' }"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarNav"
            >Herstellingen</router-link
          >
        </li>
        <li class="nav-item text-center">
          <router-link
            :to="{ name: 'Treinen NZ Corridor' }"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarNav"
            >Treinen NZ Corridor</router-link
          >
        </li>
        <li class="nav-item text-center">
          <router-link
            :to="{ name: 'Materieel Beschikbaarheid' }"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarNav"
            >MaterieelBeschikbaarheid</router-link
          >
        </li>
        <li class="nav-item text-center">
          <router-link
            :to="{ name: 'Colofon' }"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarNav"
            >Colofon</router-link
          >
        </li>
        <li class="nav-item text-center">
          <a class="nav-link" href="#" @click="logUserOut">Uitloggen</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import store from "@/store";
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  name: "Navbar",
  methods: {
    async logUserOut() {
      var myMsal = new PublicClientApplication(store.state.msalConfig);
      localStorage.removeItem("userIdToken");
      localStorage.removeItem("userAccessToken");
      await myMsal.logoutRedirect();
    },
  },
};
</script>

<style lang="scss">
nav {
  min-height: 100px;
  -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);
  display: block !important;
  padding-top: 20px !important;

  #pageTitle {
    @media (max-width: $tablet) {
      font-size: 0.75rem;
    }
  }

  .navbar-toggler {
    float: right;
  }
  a,
  p {
    color: $ns-blue !important;
    font-weight: bolder;
  }
  p {
    cursor: pointer;
    font-size: 1.2rem;
  }
  .navbar-brand {
    color: $ns-blue !important;
    font-size: 20px;
    font-weight: bold;

    .logo {
      width: 100px;
      margin-right: 40px;
      &:hover {
        color: $ns-yellow;
      }
    }
  }
}
</style>