<template>
  <div>
    <h1>O&S Direct dashboard door Team O&S Directen</h1>
    <p>Contactgegevens:</p>
    <p>Storingen en incidenten: NS Service desk via Mijn IT Portaal</p>
    <p>
      Algemene vragen kunnen naar:
      <a href="mailto:teamosdirecten@ns.nl">teamosdirecten@ns.nl</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Colofon",
    };
  },
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
  },
};
</script>

<style>
h1 {
  padding: 4rem;
  align-content: center;
}
p {
  padding-left: 150px;
  align-content: center;
}
</style>