<template>
  <div v-if="loading" class="card loading">
    <div class="spinner-border m-5 mx-auto" role="status"></div>
    <span class="mx-auto loading-text">Laden...</span>
  </div>
</template>

<script>
export default {
     props: ['loading'],
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  left: 40%;
  top: 35%;
  width: 20%;
  z-index: 1200;
  box-shadow: $shadow-light;
  .loading-text {
    font-size: 1.5rem;
    color: $ns-blue;
  }
}
</style>