<template>
  {{ formatedDate ?? "-" }}
</template>

<script>
export default {
  props: ["timestamp"],
  computed: {
    formatedDate() {

if (this.timestamp === "" || this.timestamp === undefined) {
        return "-";
      }
      const delimiter = "-";
      const start = 1;

      const date = this.timestamp.split("T")[0];
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split(delimiter).slice(start)[1];

      const time = this.timestamp.split("T")[1];
      const displayTime = time.split("+")[0];
      //noshow seconds
      const displayName = displayTime.slice(0, -3);

      const displayDate = `${day}-${month}-${year}`;

      return displayDate + " " + displayName;
    },
  },
};
</script>

