<template>
  <table class="table mt-4" aria-label="SamenvattingRedenBinnenkomst">
    <thead>
      <tr>
        <th>Activiteit</th>
        <th>Aantal stellen</th>
        <th>Aantal bakken</th>
      </tr>
    </thead>
    <tbody>
      <tr class="SRB-heading">
        <td colspan="3">Inzetbaar Materieel</td>
      </tr>
      <tr
        v-for="activiteit in samenvatting['inzetbaar']"
        :key="activiteit.ntrsnmaintDisplay"
      >
        <td
          class="cursor-pointer"
          @click="activiteitFilter(activiteit.ntrsnmaintDisplay)"
        >
          {{ activiteit.ntrsnmaintDisplay ?? "-" }}
        </td>
        <td>{{ activiteit.aantalStellen ?? "-" }}</td>
        <td>{{ activiteit.aantalBakken ?? "-" }}</td>
      </tr>
      <tr v-if="samenvatting['inzetbaar'].length === 0">
        <td>Geen data beschikbaar</td>
        <td></td>
        <td></td>
      </tr>
      <tr class="SRB-heading">
        <td colspan="3">Niet Inzetbaar Materieel</td>
      </tr>
      <tr
        v-for="activiteit in samenvatting.nietInzetbaar"
        :key="activiteit.ntrsnmaintDisplay"
      >
        <td
          class="cursor-pointer"
          @click="activiteitFilter(activiteit.ntrsnmaintDisplay)"
        >
          {{ activiteit.ntrsnmaintDisplay ?? "-" }}
        </td>
        <td>{{ activiteit.aantalStellen ?? "-" }}</td>
        <td>{{ activiteit.aantalBakken ?? "-" }}</td>
      </tr>
      <tr v-if="samenvatting.nietInzetbaar.length === 0">
        <td>Geen data beschikbaar</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["filteredallAssetsForMaterieelBeschikbaarheid"],
  emits: ["activiteitSelection"],
  data() {
    return {};
  },
  methods: {
    activiteitFilter(activiteit) {
      this.$emit("activiteitSelection", activiteit);
    },
    customSort(inputArray)
    {
        return Object.values(inputArray).sort(
        (a, b) => {
          if (a.ntrsnmaintDisplay === 'In dienst') { return -1 }
          if (b.ntrsnmaintDisplay === 'In dienst') { return 1 }
          if (a.ntrsnmaintDisplay < b.ntrsnmaintDisplay) return -1;
          if (a.ntrsnmaintDisplay > b.ntrsnmaintDisplay) return 1;
          return 0;
        }
      );
    }
  },
  computed: {
    samenvatting() {
      const samenvatting = [];
      samenvatting.inzetbaar = [];
      samenvatting.nietInzetbaar = [];

      if (this.filteredallAssetsForMaterieelBeschikbaarheid.length === 0) {
        return samenvatting;
      }

      // fill array with empty records
      this.filteredallAssetsForMaterieelBeschikbaarheid.map((asset) => {
        const isAssetInzetbaar = asset.inzetbaar === "Ja" ? "inzetbaar" : "nietInzetbaar"
        if (
          samenvatting[isAssetInzetbaar][asset.ntrsnmaintDisplay] === undefined
        ) {
          samenvatting[isAssetInzetbaar][asset.ntrsnmaintDisplay] = {
            ntrsnmaintDisplay: asset.ntrsnmaintDisplay,
            aantalBakken: 0,
            aantalStellen: 0,
          }
        }
      })

      // add number of coaches and number of trains to the correct record
      this.filteredallAssetsForMaterieelBeschikbaarheid.map((asset) => {
        const isAssetInzetbaar = asset.inzetbaar === "Ja" ? "inzetbaar" : "nietInzetbaar"
        samenvatting[isAssetInzetbaar][asset.ntrsnmaintDisplay].aantalBakken += asset.ntnocoaches;
        samenvatting[isAssetInzetbaar][asset.ntrsnmaintDisplay].aantalStellen += 1;
      });

      samenvatting.inzetbaar = this.customSort(samenvatting.inzetbaar)
      samenvatting.nietInzetbaar = this.customSort(samenvatting.nietInzetbaar)
      return samenvatting;
    },
  },
};
</script>
<style lang="scss">
th {
  background-color: rgb(32, 5, 82);
  color: white;
  white-space: nowrap;
}
.SRB-heading {
  border-bottom: 3px solid $ns-blue;
  border-top: 3px solid $ns-blue;
  color: $ns-blue;
  font-weight: bolder;
}
</style>