<template>
  <div class="container">
    <div class="button-group float-left mt-4 mb-4 w-25">
      <LocationSelect
        @locationSelection="handleLocationChange"
        :currentSelection="$route.params.locationIds"
        :key="locationSelection"
      />
    </div>
    
    <div v-if="loading" class="card loading">
      <div class="spinner-border m-5 mx-auto" role="status"></div>
      <span class="mx-auto loading-text">Laden...</span>
    </div>

    <table
      v-if="!loading"
      class="table mt-4"
      aria-label="Lijst met Herstellingen"
    >
      <thead>
        <tr>
          <th scope="col">Tijd over</th>
          <th scope="col">Gepland einde</th>
          <th scope="col">Stelnr</th>
          <th scope="col">Locatie</th>
          <th scope="col">Status</th>
          <th scope="col">Omschrijving</th>
          <th scope="col">WO</th>
          <th scope="col">Werk type</th>
          <th scope="col">Werklocatie</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="herstelling in sortedWOs"
          :key="herstelling.id"
        >
          <SingleHerstelling :herstelling="herstelling" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config.js";
import SingleHerstelling from "@/components/Herstellingen/SingleHerstelling.vue";
import LocationSelect from "@/components/LocationSelect.vue";
import { sortWOsByFinishDate } from '../../utils.js';

export default {
  name: "HerstellingenWorkOrders",
  components: {
    SingleHerstelling,
    LocationSelect,
  },

  // below we set the column on which the sorting is mandatory. Next sets the sorting ascending which causes the oldest "schedFinish" above
  data() {
    return {
      title: "Herstellingen overzicht",
      interval: null,
      herstellingen: [],
      loading: false,
      config: config ?? { backendUri: '' },
      errors: [],
    };
  },

  computed: {
    sortedWOs() {
        return sortWOsByFinishDate(this.herstellingen)
    }
  },

  mounted() {
    // Interval set to 10 minutes (600.000 milliseconds)
    this.getWorkOrders();
  },
  
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
  },
  
  beforeUnmount() {
    clearInterval(this.interval);
  },
  
  methods: {
    // FIXME: this is being triggered twice
    handleLocationChange(locationIds) {
      if(locationIds.length === 0) {
        this.$router.push({ 
          name: "HerstellingenLocations",
        });  
      } else {
        this.$router.push({ 
          name: "HerstellingenWorkOrders", 
          params: { locationIds: locationIds.join(',') },
        });

        this.getWorkOrders();
      }
    },

    async getWorkOrders() {
      this.loading = true;
      this.herstellingen = [];
      // Make API call to backend to get locations based on location selection
      await axios({
        method: 'get',
        url: this.config.backendUri + "/get-herstellingen?locations=" + this.$route.params.locationIds,
        headers: { Authorization: `Bearer ${localStorage.getItem("userIdToken")}` },
      }).then((response) => {
          this.herstellingen = response.data;
          this.loading = false;
          this.interval = setInterval(this.getWorkOrders, 600000);
        })
        .catch(async (error) => {            
          if (error.response.status === 401) {
            await this.logIn();
            this.getWorkOrders();
          }
          this.errors.push(error);
        });
    },
  },
};
</script>
