<template>
      <table class="table mt-4" aria-label="TreinstellenRedenBinnenkomst">
        <thead>
          <tr>
            <th scope="col">Lijst Treinstellen</th>
            <th scope="col" colspan="4">
              <span
                @click="clearActiviteitSelection()"
                class="fas fa-times float-right pb-1 cursor-pointer"
              ></span>
            </th>
          </tr>
          <tr>
            <td @click="sort('serialnum')" scope="col">
              Stel / Stam
              <span
                :class="[
                  currentSort === 'serialnum'
                    ? currentSortDir === 'asc'
                      ? 'fas fa-sort-up'
                      : 'fas fa-sort-down'
                    : 'fas fa-sort',
                ]"
              >
              </span>
            </td>
            <td @click="sort('status')" scope="col">
              Status
              <span
                :class="[
                  currentSort === 'status'
                    ? currentSortDir === 'asc'
                      ? 'fas fa-sort-up'
                      : 'fas fa-sort-down'
                    : 'fas fa-sort',
                ]"
              >
              </span>
            </td>
            <td @click="sort('status_description')" scope="col">
              Inzetbaar
              <span
                :class="[
                  currentSort === 'inzetbaar'
                    ? currentSortDir === 'asc'
                      ? 'fas fa-sort-up'
                      : 'fas fa-sort-down'
                    : 'fas fa-sort',
                ]"
              >
              </span>
            </td>
            <td @click="sort('plusanplocationDisplay')" scope="col">
              Locatie
              <span
                :class="[
                  currentSort === 'plusanplocationDisplay'
                    ? currentSortDir === 'asc'
                      ? 'fas fa-sort-up'
                      : 'fas fa-sort-down'
                    : 'fas fa-sort',
                ]"
              >
              </span>
            </td>
            <td @click="sort('ntrsnmaintDisplay')" scope="col">
              Reden Binnenkomst
              <span
                :class="[
                  currentSort === 'ntrsnmaintDisplay'
                    ? currentSortDir === 'asc'
                      ? 'fas fa-sort-up'
                      : 'fas fa-sort-down'
                    : 'fas fa-sort',
                ]"
              >
              </span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="asset in sortedallAssetsForMaterieelBeschikbaarheid"
            :key="asset.id"
          >
            <td>
              {{ asset.serialnum ?? "-" }}
            </td>
            <td>
              {{ asset.status ?? "-" }}
            </td>
            <td>
              {{ asset.inzetbaar ?? "-" }}
            </td>
            <td>
              {{ asset.plusanplocationDisplay ?? "-" }}
            </td>
            <td>
              {{ asset.ntrsnmaintDisplay ?? "-" }}
            </td>
          </tr>
        </tbody>
      </table>
</template>

<script>
export default {
  name: "TreinstellenRedenBinnenkomst",
  props: ["filteredallAssetsForMaterieelBeschikbaarheid"],
  emits: ["clearActiviteitSelection"],
  data() {
    return {
      title: "TreinstellenRedenBinnenkomst",
      asset: [],
      currentSort: "serialnum",
      currentSortDir: "desc",
      workorders: [],
      locationSelection: [],
      loading: false,
    };
  },
  methods: {
    // method "sort" sets the possibility to sort the columns from ascending to descending and reverse
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    // If the cross is selected empty activiteiten selection on parent
    clearActiviteitSelection() {
      this.$emit("clearActiviteitSelection");
    },
  },
  computed: {
    sortedallAssetsForMaterieelBeschikbaarheid() {
      // Sorting all the Assets For Materieel Beschikbaarheid
      const sortedallAssetsForMaterieelBeschikbaarheid = Object.values(
        this.filteredallAssetsForMaterieelBeschikbaarheid,
        "sorted"
      );
      return sortedallAssetsForMaterieelBeschikbaarheid.sort((a, b) => {
        // modifier determines if the sort order is ascending (default) or descending
        let modifier = 1;
        if (this.currentSortDir === "asc") modifier = -1;

        var n = a[this.currentSort].localeCompare(
          b[this.currentSort],
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
        // return -1 (sort below), +1 (sort above) or 0 (no order) for comparing a to b
        return n * modifier;
      });
    },
  },
};
</script>

<style>
.fa-times {
  color: white;
}

</style>