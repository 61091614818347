import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HerstellingenLocations from '../views/herstellingen/Locations.vue'
import HerstellingenWorkOrders from '../views/herstellingen/WorkOrders.vue'
import Presentatie from '../views/Presentatie.vue'
import SBProductiedashboard from '../views/SBProductiedashboard.vue'
import TreinenNZCorridor from '../views/TreinenNZCorridor.vue'
import MaterieelBeschikbaarheid from '../views/MaterieelBeschikbaarheid.vue'
import Colofon from '@/views/Colofon.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/SBProductiedashboard',
    name: 'SB Productiedashboard',
    component: SBProductiedashboard
  },

  {
    path: '/SBProductiedashboard/:urlLocationSelection',
    component: SBProductiedashboard,
    props: true
  },

  {
    path: '/Herstellingen',
    name: 'HerstellingenLocations',
    component: HerstellingenLocations
  },

  {
    path: '/Herstellingen/:locationIds',
    name: 'HerstellingenWorkOrders',
    component: HerstellingenWorkOrders,
    // props: true // -- wat betekent 'props: true?'
  },

  {
    path: '/Presentatie/:locationIds',
    name: 'Presentatie',
    component: Presentatie,
    // props: true // -- wat betekent 'props: true?'
  },

  {
    path: '/Treinen-NZ-Corridor',
    name: 'Treinen NZ Corridor',
    component: TreinenNZCorridor
  },

  {
    path: '/MaterieelBeschikbaarheid',
    name: 'Materieel Beschikbaarheid',
    component: MaterieelBeschikbaarheid
  },
  
  {
    path: '/Colofon',
    name: 'Colofon',
    component: Colofon
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (localStorage.getItem("userIdToken") !== null || localStorage.getItem("userAccessToken") !== null) {
//     return next()
//   } else {
//     LoginMixin.methods.logIn() 
//   }
// })


export default router
