<template>
  <td>{{ SingleStam.serialnum }}</td>
  <td>{{ SingleStam.status }}</td>
  <td>{{ SingleStam.vlootregiestatus }}</td>
  <td>{{ SingleStam.plusamodelDisplay }}</td>
  <td>{{ SingleStam.plusanplocationDisplay }}</td>
  <td>{{ SingleStam.ntrsnmaint }}</td>
  <td @click="toggle = !toggle">
    <span v-show="toggle">
      <span v-for="(loc, index) in sortedSamenstelling.locs" :key="loc">
        <span :class="loc.assettype">{{ getDisplaySerialNumAndKm(loc) }}</span>
        <span v-if="index === 0"> ... </span>
      </span>
    </span>

    <span v-show="!toggle">
      <span v-for="asset in sortedSamenstelling.delen" :key="asset">
        <span :class="asset.assettype">{{ getDisplaySerialNumAndKm(asset) }}</span
        ><br />
      </span>
    </span>
  </td>
</template>

<script>
export default {
  props: ["SingleStam"],
  data() {
    return {
      toggle: true,
    };
  },
  methods: {
    getDisplaySerialNumAndKm(asset) {
      if(asset.assettype === 'LOC' || asset.assettype === 'EENHEID') {
        if(asset.p_left) {
          return `${asset.serialnum} (${asset.p_left} km)`
        } 
        return `${asset.serialnum} (-)`;
      }
      return asset.serialnum;
    }
  },
  computed: {
    sortedSamenstelling() {
      if (this.SingleStam.ntnocoaches > 1) {
        var sortedSamenstelling = new Array(this.SingleStam.ntnocoaches);
        for (let i = 1; i <= this.SingleStam.ntnocoaches; i++) {
          sortedSamenstelling[i - 1] = this.SingleStam.samenstelling.filter(
            (stam) => stam.id == i
          )[0] ?? { id: "", serialnum: "Geen", assettype: "GEEN" }; // als de zoektocht naar de data van de stam faalt, dan dummy Geen/GEEN invullen
        }
        const locs = [
          sortedSamenstelling[0],
          sortedSamenstelling[this.SingleStam.ntnocoaches - 1],
        ];
        return { delen: sortedSamenstelling, locs: locs };
      } else {
        return {
          // als er niets gevonden wordt, vullen met dummy data om een foutmelding te voorkomen:
          delen: { id: "", serialnum: "Geen", assettype: "GEEN" },
          locs: { id: "", serialnum: "Geen", assettype: "GEEN" },
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.LOC {
  color: $ns-blue;
}
.RIJTUIG {
  color: $ns-light-blue;
}
.EENHEID {
  color: $ns-blue;
}
.GEEN {
  color: Red;
}
</style>