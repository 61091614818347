<template>
  <td
    :class="[
      'alnright',
      { telaat: Date.parse(herstelling.schedfinish) < Date.parse(new Date()) },
    ]"
  >
    {{ timer }}
  </td>
  <td class="alnright">
    <TimeDateFormatter :timestamp="herstelling.schedfinish" />
  </td>
  <td>{{ herstelling.nttopserialnum ?? "-" }}</td>
  <td>{{ herstelling.repairfacility ?? "-" }}</td>
  <td>{{ herstelling.status ?? "-" }}</td>
  <td data-toggle="tooltip" :title="herstelling.description">
    {{ trimmedWorkorderDescription }}
  </td>
  <td>{{ herstelling.wonum ?? "-" }}</td>
  <td>{{ herstelling.worktype ?? "-" }}</td>
  <td>{{ herstelling.worklocation ?? "-" }}</td>
</template>

<script>
import TimeDateFormatter from "@/components/Formatters/TimeDateFormatter";

export default {
  components: { TimeDateFormatter },
  props: ["herstelling"],
  computed: {
    trimmedWorkorderDescription() {
      const length = 30;
      var workorderDescription = this.herstelling.description;
      return workorderDescription.length > length
          ? workorderDescription.substring(0, length - 3) + "..."
          : workorderDescription;
    },
  },
  data() {
    return {
      countingDown: null,
      timer: null,
    };
  },
  methods: {
    calculateTime() {
      if (this.herstelling.schedfinish === "") {
        this.timer = "-";
      } else {
        //Convert dates to milliseconds
        const scheduledFinish = Date.parse(this.herstelling.schedfinish);
        const now = Date.parse(new Date());

        //calculate remaining time in milliseconds
        const remainingTime = scheduledFinish - now;
        //Convert milliseconds to seconds, minutes and hours
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;


        //calculations for remaining hours, minutes and seconds, but seconds noshow
        var displayHour = Math.abs(Math.floor(remainingTime / hour));
        var displayMinute = (
          "0" + Math.abs(Math.floor((remainingTime % hour) / minute))
        ).slice(-2);

        this.timer = `${displayHour}:${displayMinute}`;
      }
    },
  },

  mounted() {
    this.countingDown = setInterval(this.calculateTime, 1000);
  },

  beforeUnmount() {
    clearInterval(this.countingDown);
  },
};
</script>

