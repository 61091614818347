<template>
  <div class="container">
    <div class="row mt-4 pr-3">
      <div class="col-12">
        <div class="button-group float-left w-25">
          <LocationSelect
            @locationSelection="setCurrentLocation"
            :currentSelection="locationSelection"
            :key="locationSelection"
          />
        </div>
        <div v-if="loading" class="card loading">
          <div class="spinner-border m-5 mx-auto" role="status"></div>
          <span class="mx-auto loading-text">Laden...</span>
        </div>
        <div v-if="locationSelection !== null" class="button-group float-right">
          <WorkorderStatusFilter
            @filterChange="currentStatus = $event"
            @selectedWorkstatuses="workStatusSelection = $event"
            :uniqueWorkstatuses="uniqueWorkstatuses"
            :clearWorkstatusFilterCheckboxes="newLocation"
          />
        </div>
        <div
          v-if="locationSelection !== null"
          class="button-group float-right mr-2"
        >
          <WorkorderCommodityGroupFilter
            @filterChange="currentCommodityGroup = $event"
            @selectedCommodityGroups="commodityGroupSelection = $event"
          />
        </div>
        <!-- This button reload the workorderdata by using get-workorders by location, getWorkorders(locationSelection) -->
        <div
          v-if="locationSelection !== null"
          class="button-group float-right mr-2"
          @click="getWorkorders()"
        >
          <button
            type="button"
            class="btn btn-primary button-refresh"
            class-tooltip="btn btn-secondary"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Data verversen"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <table
      v-if="locationSelection !== null && !loading"
      class="table mt-4"
      aria-label="Lijst met Werkorders"
    >
      <thead>
        <tr>
          <th @click="sort('nttopserialnum')" scope="col">
            Stelnr
            <i
              :class="[
                currentSort === 'nttopserialnum'
                  ? currentSortDir === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down'
                  : 'fas fa-sort',
              ]"
            >
            </i>
          </th>
          <th @click="sort('worktype')" scope="col">
            Type werk
            <i
              :class="[
                currentSort === 'worktype'
                  ? currentSortDir === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down'
                  : 'fas fa-sort',
              ]"
            >
            </i>
          </th>
          <th scope="col">Omschrijving</th>
          <th scope="col">Normtijd</th>
          <th scope="col">Prioriteit</th>
          <th scope="col">Locatie</th>
          <th scope="col">Status</th>
          <th @click="sort('sneconstraint')" scope="col">
            Aankomsttijd
            <i
              :class="[
                currentSort === 'sneconstraint'
                  ? currentSortDir === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down'
                  : 'fas fa-sort',
              ]"
            >
            </i>
          </th>
          <th @click="sort('fnlconstraint')" scope="col">
            Vertrektijd
            <i
              :class="[
                currentSort === 'fnlconstraint'
                  ? currentSortDir === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down'
                  : 'fas fa-sort',
              ]"
            >
            </i>
          </th>
          <th scope="col">Geplande einde</th>
          <th scope="col">Spoortype</th>
          <th @click="sort('ntraildesc')" scope="col">
            Spoor
            <i
              :class="[
                currentSort === 'ntraildesc'
                  ? currentSortDir === 'asc'
                    ? 'fas fa-sort-up'
                    : 'fas fa-sort-down'
                  : 'fas fa-sort',
              ]"
            >
            </i>
          </th>
          <th scope="col">Aanvullende informatie</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="workorder in filteredWorkorders" :key="workorder.id">
          <SingleWorkorder :workorder="workorder" />
        </tr>
        <tr v-if="filteredWorkorders.length === 0">
          <td colspan="13">Geen Werkorders Gevonden.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="locationSelection === null">
    <ButtonSelect @buttonSelection="handleButtonLocationSelection($event)" />
  </div>
</template>

<script>
import axios from "axios";
import SingleWorkorder from "@/components/SBProductieDashboard/SingleWorkorder.vue";
import LocationSelect from "@/components/LocationSelect.vue";
import WorkorderStatusFilter from "@/components/Filters/WorkorderStatusFilter.vue";
import WorkorderCommodityGroupFilter from "@/components/Filters/WorkorderCommodityGroupFilter.vue";
import ButtonSelect from "@/components/Buttons.vue";
import config from "@/config.js";

export default {
  name: "WorkOrder",
  components: {
    ButtonSelect,
    SingleWorkorder,
    LocationSelect,
    WorkorderStatusFilter,
    WorkorderCommodityGroupFilter,
  },
  props: {
    urlLocationSelection: {
      type: String,
      default: null,
    },
  },
  // below we set the column on which the sorting is mandatory. Next sets the sorting ascending which causes the oldest "startdate" above
  data() {
    return {
      title: "SB Productiedashboard",
      workorders: [],
      currentSort: "fnlconstraint",
      currentSortDir: "asc",
      currentStatus: "all",
      currentCommodityGroup: "all",
      workStatusSelection: [],
      commodityGroupSelection: [],
      locationSelection: null,
      buttonLocationSelection: null,
      uniqueWorkstatuses: null,
      loading: false,
      config: config ?? { backendUri: "" },
    };
  },
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
    this.handleUrlLocationSelection();
  },
  methods: {
    // method "sort" sets the possibility to sort the columns from ascending to descending and reverse
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    async getWorkorders() {
      if (this.locationSelection !== null) {
        this.loading = true;
        this.workorders = [];

        await axios
          .get(
            this.config.backendUri +
              "/get-workorders?locations=" +
              this.locationSelection,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("userIdToken")}`,
              },
            }
          )
          .then((response) => {
            this.workorders = response.data;
            this.loading = false;
            if (response.data !== "noWorkorders") {
              this.getUniqueWorkStatuses();
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.logIn();
            }
            this.errors.push(error);
          });
      }
    },
    setCurrentLocation(x) {
      if (x.length === 0) {
        this.locationSelection = null;
      } else {
        this.locationSelection = x.join(",");
        return this.getWorkorders();
      }
    },
    handleUrlLocationSelection() {
      if (this.urlLocationSelection !== null) {
        this.locationSelection = this.urlLocationSelection;
        return this.getWorkorders();
      }
    },
    handleButtonLocationSelection(buttonValues) {
      if (buttonValues !== null) {
        this.locationSelection = buttonValues;
        return this.getWorkorders();
      }
    },

    getUniqueWorkStatuses() {
      const workstatuses = {
        status: this.workorders.map((workorder) => workorder.status),
      };
      this.uniqueWorkstatuses = workstatuses.status
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();

      return this.uniqueWorkstatuses;
    },
  },
  computed: {
    sortedWorkorders() {
      // Sorting the workorders
      const sortedWorkorders = Object.values(this.workorders, "sorted");
      return sortedWorkorders.sort((a, b) => {
        // modifier determines if the sort order is ascending (default) or descending
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] === "") {
          return 1;
        }
        if (b[this.currentSort] === "") {
          return -1;
        }
        var n = a[this.currentSort].localeCompare(
          b[this.currentSort],
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
        // return -1 (sort below), +1 (sort above) or 0 (no order) for comparing a to b
        return n * modifier;
      });
    },
    filteredWorkorders() {
      return [...this.sortedWorkorders].filter((workorder) => {
        //filter on workstatus
        if (this.workStatusSelection.length !== 0) {
          if (!this.workStatusSelection.includes(workorder.status))
            return false;
        }

        // filter on commodityGroup
        if (this.commodityGroupSelection.length !== 0) {
          if (!this.commodityGroupSelection.includes(workorder.commoditygroup))
            return false;
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-icon {
  color: white;
}
th {
  background-color: rgb(32, 5, 82);
  color: white;
  white-space: nowrap;
}
.fa-expand-alt {
  margin-left: 8px;
  margin-right: 0px;
}

.button-group {
  ul {
    color: $ns-blue;
  }
  .btn-primary {
    background-color: $ns-blue !important;
    border-color: $ns-blue !important;
  }
}
</style>
