<template>
  <div class="container">
    <div class="row">
      <div class="tile-col col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="tile" @click="handleNavigation('SB Productiedashboard')">
          <div class="tile-icon"><span class="fas fa-file-word"></span></div>
          <div class="tile-text">SB Productiedashboard</div>
        </div>
      </div>
      <div class="tile-col col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="tile" @click="handleNavigation('HerstellingenLocations')">
          <div class="tile-icon"><span class="fas fa-tools"></span></div>
          <div class="tile-text">Herstellingen</div>
        </div>
      </div>
      <div class="tile-col col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="tile" @click="handleNavigation('Treinen NZ Corridor')">
          <div class="tile-icon"><span class="fas fa-train"></span></div>
          <div class="tile-text">Treinen NZ Corridor</div>
        </div>
      </div>
      <!--div class="tile-col col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="tile" @click="handleNavigation('Materieel Beschikbaarheid')">
          <div class="tile-icon"><i class="fas fa-plane"></i></div>
          <div class="tile-text">Materieel Beschikbaarheid</div>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
import router from "../router/index.js";

export default {
  name: "Home",
  data() {
    return {
      title: "OSDirect Dashboard",
    };
  },
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
  },
  methods: {
    handleNavigation(routeName) {
      router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.tile-col {
  padding: 4rem;
  @media (max-width: $laptop) {
    padding: 1rem;
  }
  @media (max-width: $laptopl) {
    padding: 1.5rem;
  }
}
.tile {
  height: 400px;
  background-color: white;
  box-shadow: $shadow-light;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-ns-blue;
  }

  &-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 6rem;
    color: $ns-blue;
  }

  &-text {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 1.4rem;
    color: $ns-blue;
    @media (max-width: $laptop) {
      font-size: 1rem;
      padding: 0px;
    }
    @media (max-width: $laptopl) {
      font-size: 1.2rem;
    }
  }
}
</style>