<template>
  <Navbar />
  <router-view />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import store from "@/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { mapState } from "vuex";

require("dotenv").config();

export default {
  name: "App",
  components: {
    Navbar,
  },
  //mixins: [login],
  computed: {
    ...mapState(["userIdToken"]),
  },
  store,
  data() {
    return {
      account: null,
    };
  },
  async beforeCreate() {
    // bypass login for local development, NODE_ENV is equal to development as long as the app isn't built
    // also bypass for presentation (has ip range check instead)
    if (process.env.NODE_ENV === "development" || window.location.href.toLowerCase().includes('presentatie')) {
      return true;
    }
    var myMsal = new PublicClientApplication(store.state.msalConfig);

    localStorage.clear();
    const authCheck = await myMsal
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        //If response is not null save accesstoken to vuex and localstorage
        if (authResult) {
          var idToken = authResult.idToken;
          var accessToken = authResult.accessToken;
          var account = authResult.account;

          //save tokens to localstorage
          localStorage.setItem("userIdToken", idToken);
          localStorage.setItem("userAccessToken", accessToken);

          //save tokens to Vuex
          store.commit("setIdToken", idToken);
          store.commit("setAccessToken", accessToken);
        }

        account = myMsal.getAllAccounts();
        if (account.length === 0) {
          // redirect anonymous user to login page
          myMsal.loginRedirect();
          return false;
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
      });

    if (authCheck === false) {
      // clear all app content from the browser first and redirect the user to login.html
      document.getElementById("app").innerHTML = "";
    }
  },
};
</script>

<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
}
body {
  background-color: $ns-grey-background;
  font-family: $ns-font !important;

  .display-4 {
    color: $ns-blue !important;
  }

  .btn-primary {
    background-color: $ns-light-blue !important;
    &:hover {
      background-color: $ns-blue;
    }
  }

  .btn-outline-primary {
    border-color: $ns-blue !important;
    color: $ns-blue !important;
  }
}
.container {
  max-width: 1700px;
}
@font-face {
  font-family: "NSsans";
  src: local("nssans-regular"),
    url(./fonts/nssans-regular.ttf) format("truetype");
}

.loading {
  position: fixed;
  left: 40%;
  top: 35%;
  width: 20%;
  z-index: 1200;
  box-shadow: $shadow-light;
  .loading-text {
    font-size: 1.5rem;
    color: $ns-blue;
  }
}

.refreshing {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  padding: 1rem;
  z-index: 1200;
  box-shadow: $shadow-light;
}

.table {
  box-shadow: $shadow-light;
  th {
    background-color: rgb(32, 5, 82);
    color: white;
    white-space: no-wrap;
  }

  th:first-of-type {
    border-radius: 10px 0 0 0;
  }

  th:last-of-type {
    border-radius: 0 10px 0 0;
  }

  tr {
    background-color: #eeeeee;
    &:hover {
      color: $ns-blue;
      font-weight: bolder;
    }
  }
  tr:nth-child(even) {
    background-color: #f3f3f3;
  }

  tr:last-of-type {
    border-bottom: 0.2rem solid $ns-blue;
  }

  .filter-icon {
    color: white;
  }

  .alnright {
    text-align: right;
  }

  .countdown-timer {
    color: $ns-blue;
  }

  .telaat {
    color: red;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
