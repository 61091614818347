<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-12">
        <div class="button-group float-left mt-4 mb-4 w-25">
          <LocationSelect @locationSelection="locationSelection = $event" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <SamenvattingMaterieelSerie
          :filteredallAssetsForMaterieelBeschikbaarheid="
            filteredallAssetsForMaterieelBeschikbaarheid
          "
          @materieelSerieSelection="materieelSerieSelection = $event"
        />
      </div>
      <div v-show="activiteitSelection.length === 0" class="col-12 col-lg-6">
        <SamenvattingRedenBinnenkomst
          :filteredallAssetsForMaterieelBeschikbaarheid="
            filteredallAssetsForMaterieelBeschikbaarheid
          "
          @activiteitSelection="activiteitSelection = $event"
        />
      </div>
      <div v-show="activiteitSelection.length !== 0" class="col-12 col-lg-6">
        <TreinstellenRedenBinnenkomst
          :filteredallAssetsForMaterieelBeschikbaarheid="
            filteredallAssetsForMaterieelBeschikbaarheid
          "
          @clearActiviteitSelection="activiteitSelection = []"
        />
      </div>
    </div>
  </div>
  <LoadingIndicator :loading="loading" />
</template>

<script>
import axios from "axios";
import config from "../config.js";
import SamenvattingMaterieelSerie from "@/components/MaterieelBeschikbaarheid/SamenvattingMaterieelSerie.vue";
import SamenvattingRedenBinnenkomst from "@/components/MaterieelBeschikbaarheid/SamenvattingRedenBinnenkomst.vue";
import TreinstellenRedenBinnenkomst from "@/components/MaterieelBeschikbaarheid/TreinstellenRedenBinnenkomst.vue";
import LocationSelect from "@/components/LocationSelect.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  components: {
    SamenvattingRedenBinnenkomst,
    SamenvattingMaterieelSerie,
    TreinstellenRedenBinnenkomst,
    LocationSelect,
    LoadingIndicator,
  },
  name: "Materieelbeschikbaarheid",
  data() {
    return {
      allAssetsForMaterieelBeschikbaarheid: [],
      title: "Materieelbeschikbaarheid",
      loading: false,
      locationSelection: [],
      activiteitSelection: [],
      materieelSerieSelection: [],
      config: config ?? { backendUri: '' }
    };
  },
  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;
    this.getAllAssetsForMaterieelBeschikbaarheid();
  },
  methods: {
    getAllAssetsForMaterieelBeschikbaarheid() {
      this.loading = true;
      // Make API call to backend to get all Asset
      axios
        .get(this.config.backendUri + "/getAssetsMaterieelBeschikbaarheid", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userIdToken")}`,
          },
        })
        .then((response) => {
          
          this.allAssetsForMaterieelBeschikbaarheid = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.logIn();
          }
          this.errors.push(error);
        });
    },
  },
  computed: {
    filteredallAssetsForMaterieelBeschikbaarheid() {
      return this.allAssetsForMaterieelBeschikbaarheid.filter((asset) => {
        // filter on location
        if (this.locationSelection.length !== 0) {
          if (!this.locationSelection.includes(asset.plusanplocation))
            return false;
        }
        // filter on activiteit
        if (this.activiteitSelection.length !== 0) {
          if (!this.activiteitSelection.includes(asset.ntrsnmaintDisplay))
            return false;
        }
        // filter on bakkenstand
        if (this.materieelSerieSelection.length !== 0) {
           // "Includes" only works on array, so sthe string is converted to a array
          var numberOfCoaches = [];
          numberOfCoaches.push(this.materieelSerieSelection[1]);
          if (!numberOfCoaches.includes(asset.ntnocoaches)) return false;
        }
        // filter on materieelserie
        if (this.materieelSerieSelection.length !== 0) {
          var materieelSerie = this.materieelSerieSelection[0];
          if (!materieelSerie.includes(asset.ntserie)) return false;
        }

        return true;
      });
    },
  },
};
</script>

