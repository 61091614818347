<template>
  <div class="container button-container">
    <div class="row">
      <div
        class="tile-col col-12 col-sm-12 col-md-6 col-lg-3"
        v-for="button in buttons"
        :key="button.name"
      >
        <div class="tile" @click="emitButtonSelection(button.value)">
          <div class="tile-icon"><span class="fas fa-train"></span></div>
          <div class="tile-text">{{ button.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["buttonSelection"],
  methods: {
    emitButtonSelection(buttonValue) {
      this.$emit("buttonSelection", buttonValue);
    },
  },
  data() {
    return {
      buttons: {
        1: {name: "Alkmaar", value: "642000,642300,642700,642200,642500,642400" },
        2: { name: "Binckhorst", value: "652100" },
        3: { name: "Eindhoven", value: "662000,663200,662200" },
        4: { name: "Groningen Vork", value: "763500" },
        5: { name: "Nijmegen", value: "636000" },
        6: { name: "Roosendaal", value: "663000,664000,663300" },
        7: { name: "Rotterdam", value: "653000,653200,653300" },
        8: { name: "Utrecht", value: "654000,655000" },
        9: { name: "Watergraafsmeer", value: "643000,643200,643001" },
        10: { name: "Zwolle", value: "632100" },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 100px;
}

.tile-col {
  padding: 2rem;
  @media (max-width: $laptop) {
    padding: 1rem;
  }
  @media (max-width: $laptopl) {
    padding: 1.5rem;
  }
}
.tile {
  height: 150px;
  background-color: white;
  box-shadow: $shadow-light;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-ns-blue;
  }

  &-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 2rem;
    color: $ns-blue;
  }

  &-text {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 1rem;
    color: $ns-blue;
    @media (max-width: $laptop) {
      font-size: 1rem;
      padding: 0px;
    }
    @media (max-width: $laptopl) {
      font-size: 1.2rem;
    }
  }
}
</style>