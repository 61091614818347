<template>
  <div class="button-group">
    <button
      type="button"
      class="btn btn-primary dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        class="filter-icon mr-2"
        alt="filter"
        src="@/assets/icons/filter--small.svg"
      />
      Filter op Variant
    </button>
    <ul class="dropdown-menu">
      <button
        class="dropdown-item"
        @click="updateFilter('all'), emptySelectedVariants()"
      >
        Alle Varianten
      </button>
      <div class="dropdown-divider"></div>
      <li v-for="uniqueVariant in uniqueVariants" :key="uniqueVariant">
        <input
          class="mr-2 ml-2"
          type="checkbox"
          :value="uniqueVariant"
          v-model="selectedVariants"
          @click="updateFilter(uniqueVariant)"
        />
        <label>{{ uniqueVariant }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "uniqueVariants",
  ],
  emits: ["filterChange", "selectedVariants"],
  data() {
    return {
      selectedVariants: [],
    };
  },
  methods: {
    updateFilter(by) {
      this.$emit("filterChange", by);
    },
    emptySelectedVariants() {
      this.selectedVariants = [];
    },
  },
  watch: {
    selectedVariants() {
      this.$emit("selectedVariants", this.selectedVariants);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  ul {
    color: $ns-blue;
    font-size: 16px;
  }
  .btn-primary {
    background-color: $ns-blue !important;
    border-color: $ns-blue !important;
  }
}
</style>


