<template>
  <td>{{ locomotief.serialnum }}</td>
  <td>{{ locomotief.ntrsnmaint }}</td>
  <td>{{ locomotief.plusanplocationDisplay }}</td>
  <td>{{ locomotief.p_left ? locomotief.p_left : '-' }}</td>
  <td><TimeDateFormatter :timestamp="locomotief.prognose" /></td>
</template>

<script>
import TimeDateFormatter from "@/components/Formatters/TimeDateFormatter";
export default { 
  components: { TimeDateFormatter },
  props: ["locomotief"] 
  };
</script>
