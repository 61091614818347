<template>
  <td>{{ workorder.nttopserialnum ?? "-" }}</td>
  <td>{{ workorder.worktype ?? "-" }}</td>
  <td data-toggle="tooltip" :title="workorder.description">
    {{ trimmedWorkorderDescription }}
  </td>
  <td><EstdurFormatter :estdur="workorder.estdur"/></td>
  <td>{{ workorder.wopriority ?? "-" }}</td>
  <td>{{ workorder.repairfacility ?? "-" }}</td>
  <td>{{ workorder.status ?? "-" }}</td>
  <td><TimeDateFormatter :timestamp="workorder.sneconstraint" /></td>
  <td><TimeDateFormatter :timestamp="workorder.fnlconstraint" /></td>
  <td><TimeDateFormatter :timestamp="workorder.schedfinish" /></td>
  <td>{{ workorder.ntoutillage }}</td>
  <td>{{ workorder.ntraildesc ?? "-" }}</td>
  <td class="additionalinformation">
    {{ joinedAdditionalInformation }}
  </td>
</template>
<script>
import TimeDateFormatter from "@/components/Formatters/TimeDateFormatter";
import EstdurFormatter from "@/components/Formatters/EstdurFormatter";

export default {
  components: { TimeDateFormatter, EstdurFormatter },
  props: ["workorder"],
  computed: {
    trimmedWorkorderDescription() {
      const length = 30;
      var workorderDescription = this.workorder.description;

      return workorderDescription.length > length
          ? workorderDescription.substring(0, length - 3) + "..."
          : workorderDescription;
    },
    joinedAdditionalInformation() {

      return this.workorder.additionalinformation.join(" | ");
    },
  },
};
</script>

<style scoped lang="scss">
.additionalinformation {
  color: $ns-blue;
}
</style>
