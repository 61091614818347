<template>
  <div>
    <treeselect
      v-model="value"
      :multiple="true"
      placeholder="Locatie selectie"
      value-consists-of="LEAF_PRIORITY"
      :limit="6"
      :options="locations"
    />
    <treeselect-value :value="value" />
  </div>
</template>

<script>
import axios from "axios";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import config from "../config.js";

export default {
  name: "location",
  components: {
    Treeselect,
  },
  props: {
    currentSelection: { default: null },
  },
  emits: ["locationSelection"],
  data() {
    return {
      locations: [],
      config: config ?? { backendUri: "" },
      value: null,
    };
  },
  created() {
    this.getLocations();
    if (this.currentSelection !== null) {
      this.setSelectedLocations();
    }
  },
  updated() {
    // If user makes a selection of locations emit this selection to parent component
    if (this.value != null) {
      const selection = Object.values(this.value);
      this.$emit("locationSelection", selection);
    }
  },
  methods: {
    setSelectedLocations() {
      this.value = this.currentSelection.split(",");
    },
    // Make API call to backend to receive a JSON object of all the locations
    async getLocations() {
      await axios
        .get(this.config.backendUri + "/get-locations", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userIdToken")}`,
          },
        })
        .then((response) => {
          const data = response.data; // raw data

          //Make a multi-dimensional array of the received locations to fill dropdown values
          const locationsArray = (function (data, root) {
            var t = {};
            data.forEach((o) => {
              Object.assign((t[o.id] = t[o.id] || {}), o);
              t[o.parent] = t[o.parent] || {};
              t[o.parent].children = t[o.parent].children || [];
              t[o.parent].children.push(t[o.id]);
            });
            return t[root].children;
          })(data, undefined);
          this.locations = locationsArray;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.logIn();
          }
          this.errors.push(error);
        });
    },
  },
};
</script>