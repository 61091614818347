<template>
  <table class="table mt-4" aria-label="SamenvattingMaterieelSerie">
    <thead>
      <tr>
        <th>Materieelserie</th>
        <th>Inzetbaar</th>
        <th>Niet Inzetbaar</th>
        <th>Onttrokken</th>
        <th>Totaal</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="asset in uniqueAssetSeries" :key="asset.id">
        <td class="ntserie" @click="materieelSerieFilter(asset)">
          {{ asset.displayName }}
        </td>
        <td>{{ asset.inzetbaar ?? "-" }}</td>
        <td>{{ asset.nietInzetbaar ?? "-" }}</td>
        <td>{{ asset.onttrokken ?? "-" }}</td>
        <td>{{ asset.totaal ?? "-" }}</td>
      </tr>
      <tr v-if="uniqueAssetSeries.length === 0">
        <td colspan="5">Geen data beschikbaar</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["filteredallAssetsForMaterieelBeschikbaarheid"],
  name: "Samenvatting Materieelserie",
  emits: ["materieelSerieSelection"],
  data() {
    return {
      isActive: false,
      materieelSerie: [],
    };
  },
  computed: {
    // filter "filteredallAssetsForMaterieelBeschikbaarheid" on unique assetseries and return all unique assetseries
    uniqueAssetSeries() {
      const uniqueAssetSeries = [];

      // loop through the assets in filteredallAssetsForMaterieelBeschikbaarheid
      this.filteredallAssetsForMaterieelBeschikbaarheid.map((asset) => {
        const assetSerie =
          asset.ntserie + "-" + this.convertToRomanNumerals(asset.ntnocoaches);
        // If asset serie is unique (ntserie + ntnocoaches combination) make new array
        if (uniqueAssetSeries[assetSerie] === undefined) {
          uniqueAssetSeries[assetSerie] = {
            displayName: assetSerie,
            ntserie: asset.ntserie,
            ntnocoaches: asset.ntnocoaches,
            inzetbaar: 0,
            nietInzetbaar: 0,
            onttrokken: 0,
            totaal: 0,
          };
        }

        uniqueAssetSeries[assetSerie].totaal =
          parseInt(uniqueAssetSeries[assetSerie].totaal) + 1;
        uniqueAssetSeries[assetSerie].onttrokken =
          parseInt(uniqueAssetSeries[assetSerie].onttrokken) +
          (asset.status === "NBVD" ? 1 : 0);
        uniqueAssetSeries[assetSerie].inzetbaar =
          parseInt(uniqueAssetSeries[assetSerie].inzetbaar) +
          (asset.status !== "NBVD" && asset.inzetbaar === "Ja" ? 1 : 0);
        uniqueAssetSeries[assetSerie].nietInzetbaar =
          parseInt(uniqueAssetSeries[assetSerie].nietInzetbaar) +
          (asset.status !== "NBVD" && asset.inzetbaar === "Nee" ? 1 : 0);
      });

      // Return all unique assetseries ordered by displayname
      return Object.values(uniqueAssetSeries).sort((a, b) => {
        if (a.displayName < b.displayName) return -1;
        else return 1;
      });
    },
  },
  methods: {
    // if materieel serie is selected send "asset.ntserie, asset.ntnocoaches" to parent else send empty array
    materieelSerieFilter(asset) {
      this.materieelSerie = [];

      this.isActive = !this.isActive;

      if (this.isActive === true) {
        this.materieelSerie = [asset.ntserie, asset.ntnocoaches];
      }

      this.$emit("materieelSerieSelection", this.materieelSerie);
    },
    // convert bakkenstand "ntnocoaches" to roman numerals
    convertToRomanNumerals(ntnocoaches) {
      var romanNumeral = ntnocoaches;
      switch (ntnocoaches) {
        case 1:
          romanNumeral = "I";
          break;
        case 2:
          romanNumeral = "II";
          break;
        case 3:
          romanNumeral = "III";
          break;
        case 4:
          romanNumeral = "IV";
          break;
        case 5:
          romanNumeral = "V";
          break;
        case 6:
          romanNumeral = "VI";
          break;
        case 7:
          romanNumeral = "VII";
          break;
        case 8:
          romanNumeral = "VIII";
          break;
        case 9:
          romanNumeral = "IX";
          break;
        case 10:
          romanNumeral = "X";
          break;
        case 11:
          romanNumeral = "XI";
          break;
        case "":
          romanNumeral = "";
          break;
      }
      return romanNumeral;
    },
  },
};
</script>
<style lang="scss">
th {
  background-color: rgb(32, 5, 82);
  color: white;
  white-space: nowrap;
}
.ntserie {
  cursor: pointer;
}
</style>