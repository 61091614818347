import orderBy from 'lodash.orderby';

/**
 * 
 * @param {*} unsortedArray  
 * @param {*} column           column name to sort on
 * @param {*} sortDirection   'asc' | 'desc'
 * @returns a new sorted (by column and direction) array, empty values at the bottom
 */

export const getSortedArray = (unsortedArray, column, sortDirection) => {
  const { emptyValues, filledValues } = unsortedArray.reduce((acc, item) => {
    const isEmpty = !item[column] || item[column] === "";
    return {
      emptyValues: isEmpty ? [...acc.emptyValues, item] : acc.emptyValues,
      filledValues: isEmpty ? acc.filledValues : [...acc.filledValues, item]
    };
  }, { emptyValues: [], filledValues: [] });
  
  const orderedValues = orderBy(filledValues, function (o) { return isNaN(o[column]) ? o[column] : new Number(o[column])}, [sortDirection]);
  return orderedValues.concat(emptyValues);
}

/**
 * 
 * @param {*} unsortedArray  
 * @returns a new sorted array, empty values at the bottom
 */
 export const sortWOsByFinishDate = (unsortedArray) => {
    return [...unsortedArray].sort((a, b) => {
        if (a.schedfinish === "") {
            return 1;
        }
        if (b.schedfinish === "") {
            return -1;
        }

        if (a.schedfinish >= b.schedfinish) { 
            return 1
        } 

        return -1 
    })
 }
  
  