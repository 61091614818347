<template>
  <div class="container">
    <div
      v-if="fetchMachineContext.error !== undefined"
      style="display: flex; align-items: center; justify-content: center; position: fixed; z-index: 999999; height: 100%; width: 100%; left: 0; top: 0; background-color: rgba(0, 0, 0, 0.4);"
    >
      <div
        style="background-color: #db0029; color: #fff; padding: 1rem; border-radius: 0.25rem; display: flex; flex-direction: column; gap: 0.5rem; box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);"
      >
        <h3
          style="border-bottom: 1px solid rgba(255, 255, 255, 0.5); padding-bottom: 0.5rem;"
        >
          Fout bij ophalen gegevens
        </h3>

        <pre style="color: #fff;">{{
          JSON.stringify(fetchMachineContext.error.message)
        }}</pre>

        <div style="display: flex; align-items: center;">
          <div style="display: flex; gap: 0.5rem;">
            <div>Aantal pogingen:</div>
            <div>{{ fetchMachineContext.numberOfRetries }}</div>
          </div>

          <div class="spinner-border" style="margin-left: auto;"></div>
        </div>
      </div>
    </div>

    <!-- initial fetching -->
    <div
      v-if="fetchMachineCurrent.matches({ fetching: { type: 'initial' } })"
      class="card loading"
    >
      <div class="spinner-border m-5 mx-auto" role="status"></div>
      <span class="mx-auto loading-text">{{
        fetchMachineCurrent.matches({ fetching: { waiting: "takesAWhile" } })
          ? "Nog even geduld..."
          : "Laden..."
      }}</span>
    </div>

    <!-- refreshing -->
    <div
      v-if="fetchMachineCurrent.matches({ fetching: { type: 'refreshing' } })"
      class="card refreshing"
    >
      <div class="spinner-border" role="status"></div>
    </div>

    <table
      v-if="fetchMachineContext.fetchedData !== undefined"
      class="table mt-4"
      aria-label="Lijst met Herstellingen"
    >
      <thead>
        <tr>
          <th scope="col">Tijd over</th>
          <th scope="col">Gepland einde</th>
          <th scope="col">Stelnr</th>
          <th scope="col">Locatie</th>
          <th scope="col">Status</th>
          <th scope="col">Omschrijving</th>
          <th scope="col">WO</th>
          <th scope="col">Werk type</th>
          <th scope="col">Werklocatie</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="herstelling in sortedWOs"
          :key="herstelling.id"
        >
          <SingleHerstelling :herstelling="herstelling" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config.js";
import SingleHerstelling from "@/components/Herstellingen/SingleHerstelling.vue";
import { createMachine, interpret, assign } from "xstate";
import { sortWOsByFinishDate } from '../utils.js';
import router from "@/router";

const getWorkOrders = () =>
  axios({
    method: "get",
    url:
      config.backendUri +
      "/v1/presentation?locationIds=" +
      encodeURIComponent(router.currentRoute.value.params.locationIds),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userIdToken")}`,
    },
  });

const fetchMachine = createMachine(
  {
    id: "fetch",
    initial: "fetching",
    context: {
      numberOfRetries: 0,
      fetchedData: undefined,
      error: undefined,
      dataLastFetchedAt: undefined,
    },
    states: {
      fetching: {
        type: "parallel",
        initial: "justStarted",
        states: {
          waiting: {
            initial: "justStarted",
            states: {
              justStarted: {
                after: {
                  [2500]: "takesAWhile",
                },
              },
              takesAWhile: {},
            },
          },

          type: {
            initial: "initial",
            states: {
              initial: {},
              refreshing: {},
              retrying: { entry: "incrementNumberOfRetries" },
            },
          },
        },

        invoke: {
          id: "getWorkOrders",
          src: getWorkOrders,
          onDone: "succeeded",
          onError: "failed",
        },
      },

      succeeded: {
        entry: ["updateFetchedData", "resetNumberOfRetries", "resetError"],
        after: {
          [1000 * 60]: "fetching.type.refreshing",
        },
      },

      failed: {
        entry: "updateError",
        after: {
          RETRY_DELAY: "fetching.type.retrying",
        },
        on: {
          RETRY: {
            target: "fetching.type.retrying",
            actions: "resetNumberOfRetries",
          },
        },
      },
    },
  },
  {
    actions: {
      incrementNumberOfRetries: assign({
        numberOfRetries: (context) => context.numberOfRetries + 1,
      }),

      resetNumberOfRetries: assign({
        numberOfRetries: () => 0,
      }),

      updateFetchedData: assign({
        fetchedData: (_, event) => event.data,
        dataLastFetchedAt: () => new Date(),
      }),

      updateError: assign({
        error: (_, event) => event.data,
      }),

      resetError: assign({
        error: () => undefined,
      }),
    },

    delays: {
      RETRY_DELAY: (context) => {
        return Math.min(context.numberOfRetries * 2500, 1000 * 60 * 5);
      },
    },
  }
);

export default {
  name: "Presentatie",
  components: {
    SingleHerstelling,
  },

  data() {
    return {
      title: "Herstellingen overzicht",
      fetchService: interpret(fetchMachine),
      fetchMachineCurrent: fetchMachine.initialState,
      fetchMachineContext: fetchMachine.context,
    };
  },

  computed: {
    sortedWOs() {
        return sortWOsByFinishDate(this.fetchMachineContext.fetchedData.data)
    }
  },

  created() {
    document.title = this.title;
    document.getElementById("pageTitle").innerHTML = this.title;

    this.fetchService
      .onTransition((state) => {
        this.fetchMachineCurrent = state;
        this.fetchMachineContext = state.context;
      })
      .start();
  },
};
</script>
